import React, { Component } from "react";
import "./style/app.css";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import { createTheme } from "react-data-table-component";
import { myTheme } from "./style/table";
// import axios from "axios";

// const BASE_URL = "https://devas.xyz";

import Login from "./Component/user/Login";
import Logout from "./Component/user/Logout";
import DashboardSale from "./Component/DashboardSale";
import DashboardAdv from "./Component/DashboardAdv";
import AssignConversionAdv from "./Component/AssignConversionAdv";
import SaleUsersAdv from "./Component/SaleUsersAdv";
import Home from "./Component/Home";
import NotFound from "./Component/NotFound";
import WrongVersion from "./Component/WrongVersion";

createTheme("myTheme", myTheme);

class App extends Component {
    componentDidMount() {
        if (localStorage.getItem("token_adv") || localStorage.getItem("token_sale")) {
            if (localStorage.getItem("theme") === "dark") {
                document.body.style = "background-color: #1b2039";
            } else {
                document.body.style = "background-color: #f9f9f9";
            }
            if (parseInt(Date.now() / 1000) - localStorage.getItem("createdDate") > 172700) {
                localStorage.clear();
                this.props.history.push("/?msg=Your+token+has+expired.+Please+login+again.");
            } else {
                this.loginTimeout();
            }

            // axios
            //     .get(`${BASE_URL}/api/v1.0/users/profile`, {
            //         headers: {
            //             "Content-Type": "application/json",
            //             Authorization: "JWT " + localStorage.getItem("token")
            //         }
            //     })
            //     .then(response => {
            //         this.loginTimeout();
            //     })
            //     .catch(error => {
            //         localStorage.clear();
            //         this.props.history.push("/?msg=Your+token+has+expired.+Please+login+again.");
            //     });
        }
    }
    loginTimeout = () => {
        var time = 172800 - (parseInt(Date.now() / 1000) - localStorage.getItem("createdDate"));
        this.loginCurrentTimeout = setTimeout(() => {
            localStorage.clear();
            this.props.history.push("/?msg=Your+token+has+expired.+Please+login+again.");
        }, time * 1000);
        if (localStorage.getItem("rememberMe") === "false") {
            window.addEventListener("beforeunload", () => {
                localStorage.clear();
            });
        }
    };
    componentWillUnmount() {
        clearTimeout(this.loginCurrentTimeout);
    }
    render() {
        return (
            <Switch>
                <Route
                    exact
                    path="/adv/login"
                    render={(routeProps) => (
                        <Login {...routeProps} key={Date.now()} loginTimeout={this.loginTimeout} isAdv={true} />
                    )}
                />
                <Route
                    exact
                    path="/sale/login"
                    render={(routeProps) => (
                        <Login {...routeProps} key={Date.now()} loginTimeout={this.loginTimeout} isAdv={false} />
                    )}
                />
                <Route exact path="/logout" render={(routeProps) => <Logout {...routeProps} key={Date.now()} />} />
                <Route exact path="/adv/dashboard" render={(routeProps) => <DashboardAdv {...routeProps} key={Date.now()} />} />
                <Route
                    exact
                    path="/adv/assignconversions"
                    render={(routeProps) => <AssignConversionAdv {...routeProps} key={Date.now()} />}
                />
                <Route exact path="/adv/saleusers" render={(routeProps) => <SaleUsersAdv {...routeProps} key={Date.now()} />} />
                <Route exact path="/sale/dashboard" render={(routeProps) => <DashboardSale {...routeProps} key={Date.now()} />} />
                {/* <Route exact path="/statistics" render={routeProps => <Statistics {...routeProps} key={Date.now()} />} /> */}
                <Route exact path="/" render={(routeProps) => <Home {...routeProps} key={Date.now()} />} />
                <Route exact path="/wrong-version" render={(routeProps) => <WrongVersion {...routeProps} key={Date.now()} />} />
                <Route exact path="/404" render={(routeProps) => <NotFound {...routeProps} key={Date.now()} />} />
                <Redirect to="/404" />
            </Switch>
        );
    }
}
export default withRouter(App);
