import axios from "axios";

export const THBtoVND = async () => {
    // try {
    //     let res = await axios.get("https://free.currconv.com/api/v7/convert?q=THB_VND&compact=ultra&apiKey=3769237307fdc07bfc4e");
    //     return res.data["THB_VND"] ? res.data["THB_VND"] : 770;
    // } catch (error) {
    //     return 770;
    // }
    return 730;
};
