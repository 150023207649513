import React, { Component } from "react";
import MainAdv from "./advbase/MainAdv";
import redirectNoToken from "../helper/redirectNoToken";
import axios from "axios";
import DataTable from "react-data-table-component";
import { customStyles } from "../style/table";
import Modal from "react-bootstrap/Modal";
import "../style/modal.css";
const columns = [
    {
        name: "Username",
        selector: "username",
        sortable: true,
    },
    // {
    //     name: "Delete",
    //     selector: "delete",
    //     sortable: true,
    //     wrap: true,
    // },
];
export default class SaleUsersAdv extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingTable: true,
            sales: [],
            showModal1: false,
        };
    }
    componentDidMount() {
        document.title = "Assign Conversions";
        redirectNoToken.bind(this);
        this.resetState();
        this.getAllSales();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    resetState = () => {
        this.setState({
            isLoadingTable: true,
            sales: [],
            showModal1: false,
            username: "",
            password: "",
        });
    };
    getAllSales = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADV_URL}/sales`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_adv"),
                },
            })
            .then((response) => {
                let data = response.data.map((v) => {
                    return {
                        username: v.username,
                        // delete: (
                        //     <button className="btn button-devas-medium" onClick={() => this.deleteDomain(v._id)}>
                        //         Delete
                        //     </button>
                        // ),
                    };
                });
                this.setState({
                    sales: data,
                    currentSale: data.length > 0 ? data[0].value : "",
                });
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleModal1Close = () => {
        this.setState({ showModal1: false });
    };
    handleSubmit = () => {
        if (this.state.username === "" || this.state.password === "") {
            alert("Please fill in all fields!");
        } else if (this.state.password.length < 6) {
            alert("Password length must be greater than 6!");
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_ADV_URL}/sales/register`,
                    {
                        username: this.state.username,
                        password: this.state.password,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_adv"),
                        },
                    }
                )
                .then((response) => {
                    this.componentDidMount();
                })
                .catch((error) => {
                    alert("Something Wrong Happended!");
                    //this.props.history.push("/error?message=" + error.message);
                });
        }
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    render() {
        return (
            <MainAdv history={this.props.history} linkTo={["/adv/saleusers"]} linkName={["Sale Users"]} selectedSection={3}>
                <div className="col-12 Statistics">
                    <div className="py-3 bg-menu">
                        <div className="px-4 d-flex justify-content-between align-items-center">
                            <h2 className="menu-h2-title mb-0">Sale Users</h2>
                            <div className="d-flex align-items-center">
                                <button
                                    className="btn button-devas d-inline-block"
                                    onClick={() => this.setState({ showModal1: true })}
                                >
                                    Create New Sale
                                </button>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={this.state.sales}
                        pagination
                        theme="myTheme"
                        className="table"
                        customStyles={customStyles}
                        noHeader={true}
                        progressPending={this.state.isLoading}
                        highlightOnHover={true}
                        defaultSortField="order"
                        paginationRowsPerPageOptions={[10,20,50,100,200,500,1000]}
                    />
                </div>
                <Modal
                    show={this.state.showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Create new sale</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <span>Username:</span>
                            <input
                                name="username"
                                type="text"
                                value={this.state.username}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Username"
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Password:</span>
                            <input
                                name="password"
                                type="password"
                                value={this.state.password}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Password"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.handleSubmit}>
                            Create User
                        </button>
                    </Modal.Footer>
                </Modal>
            </MainAdv>
        );
    }
}
