import React, { Component } from "react";
import { Link } from "react-router-dom";

import Sidebar from "./Sidebar";
import versionCheck from "../../helper/versionCheck";
import "../../style/main.css";

export default class Main extends Component {
    componentDidMount() {
        versionCheck.bind(this)();
        if (!localStorage.getItem("token_adv") && !localStorage.getItem("token_sale")) {
            this.props.history.push("/");
        }
    }
    render() {
        return (
            <Sidebar history={this.props.history} selectedSection={this.props.selectedSection}>
                <div className="main">
                    <div className="main-header"></div>
                    <div className="container-fluid">
                        <div className="row">
                            <nav className="col main-navigation d-flex align-items-center">
                                <ul className="list-none m-0 pl-0 d-flex">
                                    <li key={-1}>
                                        <Link to="/" className="previous-link">
                                            Home
                                        </Link>
                                    </li>
                                    {this.props.linkTo.map((v, i) => (
                                        <li key={i}>
                                            <Link
                                                to={v}
                                                className={i === this.props.linkTo.length - 1 ? "current-link" : "previous-link"}
                                            >
                                                {" > "}{this.props.linkName[i]}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                            {this.props.children}
                            <div className="copyright">
                                <div>
                                    Copyright © 2020 <span>Devas</span>. All rights reserved.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        );
    }
}
