export default function redirectNoToken() {
    if (!localStorage.getItem("token_adv") && !localStorage.getItem("token_sale")) {
        console.log("redirectNoToken")
        this.props.history.push("/");
    } else {
        if(localStorage.getItem("token_adv") && this.props.location.pathname.includes("/sale")){
            this.props.history.push("/");
        }
        if(localStorage.getItem("token_sale") && this.props.location.pathname.includes("/adv")){
            this.props.history.push("/");
        }
    }
}
