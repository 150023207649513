import React, { Component } from "react";
import axios from "axios";

export default class WrongVersion extends Component {
    componentDidMount() {
        axios
            .get(`${process.env.REACT_APP_ADV_URL}/app-version`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (process.env.REACT_APP_VERSION === response.data.version) {
                    this.props.history.push("/");
                }
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message);
            });
    }
    render() {
        var theme = localStorage.getItem("theme") ? localStorage.getItem("theme") : "light";
        return (
            <div
                className={`${theme}-theme bg-menu d-flex w-100vw h-100vh justify-content-center align-items-center font-raleway flex-column`}
            >
                <div className="container">
                    <h1 className="display-1 text-color text-center">Old App Version</h1>
                    <h2 className="mt-3 text-color text-center mt-4">
                        Your app is currently in an older version. Please reload this page! You can follow this{" "}
                        <a href="https://en.wikipedia.org/wiki/Wikipedia:Bypass_your_cache">guide</a> to learn more about
                        reloading your web page.
                    </h2>
                </div>
            </div>
        );
    }
}
