import React, { Component } from "react";
import { moneyFormat } from "../../helper/helper";
import "../../style/statistics.css";

export default class SummaryStatistics extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        var { approved_rate, approved, new_conv, callbacks, trash, customer_reject, duplicated, allConversions } = this.props;
        return (
            <div className="SummaryStatistics col-12 mb-4">
                <div className="row">
                    <div className="col-6 order-0 col-lg-3">
                        <div className="mx-1 bg-menu px-3 py-3">
                            <div className="d-flex">
                                <div>
                                    <h5>All Conversions</h5>
                                    <h4 className="text-blue cursor-pointer" onClick={() => this.props.changeStatus("")}>
                                        {allConversions}
                                    </h4>
                                </div>
                                <div className="ml-4">
                                    <h5>Approval Rate</h5>
                                    <h4 className="text-blue">{approved_rate}%</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-3 mt-lg-0 order-2 order-lg-1 col-lg-3">
                        <div className="mx-1 bg-menu px-3 py-3">
                            <h5>Approved</h5>
                            <h4 className="text-green cursor-pointer" onClick={() => this.props.changeStatus("approved")}>
                                {approved}
                            </h4>
                        </div>
                    </div>
                    <div className="col-6 order-1 order-lg-2 col-lg-3">
                        <div className="mx-1 bg-menu px-3 py-3">
                            <div className="d-flex">
                                <div>
                                    <h5>New</h5>
                                    <h4 className="text-orange cursor-pointer" onClick={() => this.props.changeStatus("new")}>
                                        {new_conv}
                                    </h4>
                                </div>
                                <div className="ml-4">
                                    <h5>Callbacks</h5>
                                    <h4
                                        className="text-orange cursor-pointer"
                                        onClick={() => this.props.changeStatus("callbacks")}
                                    >
                                        {callbacks}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-3 mt-lg-0 order-3 col-lg-3">
                        <div className="mx-1 bg-menu px-3 py-3">
                            <div className="d-flex">
                                <div className="">
                                    <h5>Rejected</h5>
                                    <h4
                                        className="text-red cursor-pointer"
                                        onClick={() => this.props.changeStatus("customer_reject")}
                                    >
                                        {customer_reject}
                                    </h4>
                                </div>
                                <div className="ml-3 ml-md-4">
                                    <h5>Trash</h5>
                                    <h4 className="text-red cursor-pointer" onClick={() => this.props.changeStatus("trash")}>
                                        {trash}
                                    </h4>
                                </div>
                                <div className="ml-4">
                                    <h5>Duplicated</h5>
                                    <h4 className="text-red cursor-pointer" onClick={() => this.props.changeStatus("duplicated")}>
                                        {duplicated}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
