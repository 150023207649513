import React, { Component } from "react";
import MainAdv from "./advbase/MainAdv";
import redirectNoToken from "../helper/redirectNoToken";
import axios from "axios";
import DataTable from "react-data-table-component";
import { customStyles } from "../style/table";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-dropdown";
import Select from "react-select";
import { selectTheme } from "../style/reactselect";
import InputAssign from "./base/inputAssign";
import "react-dropdown/style.css";
import "../style/dropdown.css";
import "../style/modal.css";
const columns = [
    {
        name: "Order Id",
        selector: "order_id",
        maxWidth: "70px",
        sortable: true,
        center: true,
    },
    {
        name: "Name",
        selector: "name",
        sortable: true,
        wrap: true,
    },
    {
        name: "Phone",
        selector: "phone",
        sortable: true,
        wrap: true,
    },
    {
        name: "Status",
        selector: "status",
        sortable: true,
        wrap: true,
    },
    {
        name: "Quantity",
        selector: "quantity",
        sortable: true,
        wrap: true,
    },
    {
        name: "Offer Name",
        selector: "offer",
        sortable: true,
        wrap: true,
    },
    {
        name: "Created Date",
        selector: "created_date",
        sortable: true,
        wrap: true,
    },
];
export default class AssignConversionAdv extends Component {
    constructor(props) {
        super(props);

        this.state = {
            conversions: [],
            isLoadingTable: true,
            selectedRows: [],
            sales: [],
            currentSale: "",
            showModal1: false,
            showModal2: false,
            salesSelection: [],
        };
    }
    componentDidMount() {
        document.title = "Assign Conversions";
        redirectNoToken.bind(this)();
        this.resetState();
        this.getConversionPool();
        this.getAllSales();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    resetState = () => {
        this.setState({
            conversions: [],
            isLoadingTable: true,
            selectedRows: [],
            sales: [],
            currentSale: "",
            showModal1: false,
            showModal2: false,
        });
    };
    getConversionPool = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADV_URL}/conversionscrm/conversionPool`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_adv"),
                },
            })
            .then((response) => {
                let data = response.data.reverse().map((v) => ({
                    _id: v._id,
                    order_id: v.order_id,
                    created_date: new Date(v.created_date).toLocaleString(),
                    modified_date: new Date(v.modified_date).toLocaleString(),
                    offer: v.offer.alias,
                    status: v.status,
                    modify_count: v.modify_count,
                    name: v.name,
                    phone: v.phone,
                    quantity: v.quantity,
                }));
                this.setState({ conversions: data });
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    onSelectedRowChange = (v) => {
        this.setState({ selectedRows: v.selectedRows });
    };
    getAllSales = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADV_URL}/sales`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_adv"),
                },
            })
            .then((response) => {
                let data = response.data.map((v) => {
                    return { value: v._id, label: v.username };
                });
                this.setState({
                    sales: data,
                    currentSale: data.length > 0 ? data[0].value : "",
                });
            })
            .catch((error) => {
                console.log(error.response.data);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleModal1Close = () => {
        this.setState({ showModal1: false });
    };
    handleModal2Close = () => {
        this.setState({ showModal2: false });
    };
    handleDropDownSaleAssign = (e) => {
        this.setState({ currentSale: e.value });
    };
    assignToSale = () => {
        if (this.state.conversions.length === 0 || this.state.selectedRows.length === 0) {
            alert("Please choose a conversion to assign!");
        } else {
            this.setState({ showModal1: true });
        }
    };
    assignSubmit = () => {
        if (this.state.currentSale === "") {
            alert("Please choose a Sale!");
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_ADV_URL}/conversionscrm/assigntosale`,
                    {
                        sale: this.state.currentSale,
                        conversions: this.state.selectedRows.map((v) => v._id),
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_adv"),
                        },
                    }
                )
                .then((response) => {
                    this.componentDidMount();
                })
                .catch((error) => {
                    alert("Something Wrong Happended!");
                    //this.props.history.push("/error?message=" + error.message);
                });
        }
    };
    handleChangeSalesSelection = (salesSelection) => {
        this.setState({ salesSelection });
    };
    changeSalesSelectionFrequency = (v, i) => {
        this.setState((state, props) => {
            return {
                salesSelection: state.salesSelection.map((value, index) => {
                    if (index === i) {
                        value.frequency = v;
                    }
                    return value;
                }),
            };
        });
    };
    assignAutoSubmit = (v, i) => {
        if (!this.state.salesSelection.every((v) => v.hasOwnProperty("frequency"))) {
            alert("Fill in all frequencies!");
        } else {
            let payload = this.state.salesSelection.map((v) => {
                return { id: v.value, frequency: v.frequency };
            });
            axios
                .post(
                    `${process.env.REACT_APP_ADV_URL}/conversionscrm/assign`,
                    payload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_adv"),
                        },
                    }
                )
                .then((response) => {
                    this.componentDidMount();
                })
                .catch((error) => {
                    alert("Something Wrong Happended!");
                    console.log(error.response.data);
                    //this.props.history.push("/error?message=" + error.message);
                });
        }
    };
    render() {
        return (
            <MainAdv
                history={this.props.history}
                linkTo={["/adv/assignconversions"]}
                linkName={["Assign New Conversions"]}
                selectedSection={2}
            >
                <div className="col-12 Statistics">
                    <div className="py-3 bg-menu">
                        <div className="px-4 d-flex justify-content-between align-items-center">
                            <h2 className="menu-h2-title mb-0">Unassigned conversions</h2>
                            <div className="d-flex align-items-center">
                                <button className="btn button-devas d-inline-block mr-2" onClick={this.assignToSale}>
                                    Assign To Sale
                                </button>
                                <button
                                    className="btn button-devas d-inline-block"
                                    onClick={() => this.setState({ showModal2: true })}
                                >
                                    Auto Assign
                                </button>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={this.state.conversions}
                        pagination
                        theme="myTheme"
                        className="table"
                        customStyles={customStyles}
                        noHeader={true}
                        progressPending={this.state.isLoading}
                        highlightOnHover={true}
                        defaultSortField="order"
                        defaultSortAsc={false}
                        selectableRows={true}
                        selectableRowsHighlight={true}
                        onSelectedRowsChange={this.onSelectedRowChange}
                        paginationRowsPerPageOptions={[10,20,50,100,200,500,1000]}
                    />
                </div>
                <Modal
                    show={this.state.showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Assign Conversions</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-3">{"Number of conversions: " + this.state.selectedRows.length}</p>
                        <div className="mb-3">
                            Sale User{": "}
                            <Dropdown
                                options={this.state.sales}
                                onChange={this.handleDropDownSaleAssign}
                                value={this.state.currentSale}
                                placeholder="Sales"
                                className="d-inline-block"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.assignSubmit}>
                            Assign
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModal2}
                    onHide={this.handleModal2Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">
                            Auto Assign {"("}round robin{")"}
                        </h2>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Choose Sales:</p>
                        <Select
                            defaultValue={""}
                            isMulti
                            name="Sales"
                            options={this.state.sales}
                            placeholder="Sales name"
                            className="devas-select box-shadow"
                            classNamePrefix="devas-select"
                            onChange={this.handleChangeSalesSelection}
                            theme={selectTheme}
                        />
                        {this.state.salesSelection.map((v, i) => (
                            <div key={i}>
                                <span>{v.label} frequency: </span>
                                <InputAssign runFunction={this.changeSalesSelectionFrequency} index={i} />
                            </div>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal2Close}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.assignAutoSubmit}>
                            Assign
                        </button>
                    </Modal.Footer>
                </Modal>
            </MainAdv>
        );
    }
}
