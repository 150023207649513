import React, { Component } from "react";

export default class inputAssign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            input: "",
        };
    }
    handleChange = (e) => {
        this.setState({ input: e.target.value }, () => this.props.runFunction(this.state.input, this.props.index));
    };
    render() {
        return (
            <>
                <input
                    type="text"
                    className="form-control search-input mt-2 w-auto d-inline-block"
                    name="quantity"
                    value={this.state.input}
                    onChange={this.handleChange}
                    placeholder="Frequency"
                />
            </>
        );
    }
}
