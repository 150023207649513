export const myTheme = {
    text: {
        primary: "var(--text)",
        secondary: "var(--text)",
    },
    background: {
        default: "var(--bg-menu)",
        hover: "var(--bg-block-inverse)",
    },
    context: {
        background: "#cb4b16",
        text: "#FFFFFF",
    },
    divider: {
        default: "var(--bg-block-inverse)",
    },
    button: {
        default: "var(--btn-table-default)",
        focus: "var(--btn-table-focus)",
        hover: "var(--btn-table-hover)",
        disabled: "var(--btn-table-disabled)",
    },
    highlightOnHover: {
        default: "var(--bg-auth)",
        text: "var(--text)",
    },
    selected: {
        default: "var(--bg-auth)",
        text: "var(--text)",
    },
};
export const customStyles = {
    table: {
        style: {
            display: "table",
        },
    },
    tableWrapper: {
        style: {
            overflowX: "auto",
        },
    },
    rows: {
        style: {
            paddingLeft: "12px",
            paddingRight: "12px",
            borderBottomWidth: "1.5px!important",
            ":hover": {
                borderBottomColor: "var(--bg-block-inverse)!important",
                outlineColor: "var(--bg-block-inverse)!important",
            },
        },
    },
    headRow: {
        style: {
            fontWeight: "700",
            paddingLeft: "12px",
            paddingRight: "12px",
            backgroundColor: "var(--bg-block-inverse)",
            minHeight: "40px",
        },
    },
    headCells: {
        style: {
            fontWeight: "700",
            fontSize: "0.8rem",
        },
    },
    cells: {
        style: {
            fontSize: "0.8rem",
        },
    },
    pagination: {
        style: {
            paddingLeft: "12px",
            paddingRight: "12px",
            justifyContent: "flex-start",
            webkitJustifyContent: "flex-start",
            borderTopWidth: "2px",
            "@media (min-width: 992px)": {
                webkitJustifyContent: "flex-end",
                justifyContent: "flex-end",
            },
        },
    },
};
