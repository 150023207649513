import React, { Component } from "react";
import Select from "react-select";
import Dropdown from "react-dropdown";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import axios from "axios";
import moment from "moment";
import "react-dropdown/style.css";
import "../style/dropdown.css";
import "../style/daterangepicker.css";
import { selectTheme } from "../style/reactselect";
import { moneyFormat } from "../helper/helper";
import { THBtoVND } from "../helper/CurrencyExchange";

import Main from "./base/Main";
import ConversionList from "./base/ConversionList";
import SummaryStatistics from "./base/SummaryStatistics";
import redirectNoToken from "../helper/redirectNoToken";
import { getLocalTimestamp, getStartOfDateTimestamp, getEndOfDateTimestamp } from "../helper/helper";
import Modal from "react-bootstrap/Modal";
import "../style/modal.css";

export default class DashboardSale extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offerIdOptions: [
                { value: "", label: "Offer ID" },
                { value: "one", label: "One" },
                { value: "two", label: "Two" },
            ],
            offerId: [],
            status: "",
            date: [new Date(), new Date()],
            conversionsList: [],
            conversions: [],
            isLoadingTable: true,
            currentConversion: {},
            newStatusList: [],
            newStatus: "",
            statusSaleMessage: "",
            quantity: "1",
            showModal: false,
            conversionPoolCount: 0,
            approved: "0",
            approved_rate: "0",
            new_conv: "0",
            callbacks: "0",
            trash: "0",
            customer_reject: "0",
            duplicated: "0",
            allConversions: "0",
            search: "",
            address: "",
            allPubComboPrice: [],
            chosenComboAlias: "",
            chosenComboPriceID: "",
            rejectedMessageInfo: "",
            recentCallbacksCount: 0,
            recentNewCount: 0,
            createdTodayCount: 0,
            modifiedTodayCount: 0,
            allPendingCount: 0,
            isComboConversion: false,
            shippingCost: 0,
            productPrice: 0,
            productQuantity: 0,
            currency: { label: "THB", value: "THB" },
        };
    }

    componentDidMount() {
        document.title = "Statistics";
        redirectNoToken.bind(this)();
        this.viewPendingConversions(new Date().setDate(new Date().getDate() - 5));
        this.getConversionPoolCount();
        this.getAllPubComboPrice();
    }

    getImportantConversionsCount = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_SALE_URL}/conversions/count_important_conversions`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_sale"),
                },
            })
            .then((res) => {
                console.log(res.data);
                this.setState({
                    recentCallbacksCount: res.data.recentCallbacks,
                    recentNewCount: res.data.recentNew,
                    createdTodayCount: res.data.createdToday,
                    modifiedTodayCount: res.data.modifiedToday,
                    allPendingCount: res.data.allPendingCount,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getAllPubComboPrice = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_SALE_URL}/pub/offer/comboPrice/all`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_sale"),
                },
            })
            .then((res) => {
                this.setState({ allPubComboPrice: res.data });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    getConversionPoolCount = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_SALE_URL}/conversions/conversionPool`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_sale"),
                },
            })
            .then((response) => {
                this.setState({ conversionPoolCount: response.data.conversion_count });
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    getConversionList = (isToday = false) => {
        this.getImportantConversionsCount();
        this.setState({ isLoadingTable: true });
        this.signal = axios.CancelToken.source();
        let from_date = moment(this.state.date[0]).startOf("date").unix() * 1000;
        let to_date = moment(this.state.date[1]).endOf("date").unix() * 1000 + 999;
        if (isToday) {
            from_date = moment(new Date()).startOf("date").unix() * 1000;
            to_date = moment(new Date()).endOf("date").unix() * 1000 + 999;
        }
        this.setState({ date: [from_date, to_date] });
        axios
            .get(`${process.env.REACT_APP_SALE_URL}/conversions?from_date=${from_date}&to_date=${to_date}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_sale"),
                },
            })
            .then((response) => {
                let data = response.data.reverse().map((v) => ({
                    _id: v._id,
                    order_id: v.order_id,
                    created_date: new Date(v.created_date).toLocaleString(),
                    modified_date: new Date(v.modified_date).toLocaleString(),
                    offer: v.offer.alias,
                    status: v.status,
                    modify_count: v.modify_count,
                    name: v.name,
                    phone: v.phone,
                    quantity: v.quantity,
                    sale_message: v.sale_message,
                    address: v.address,
                    message: v.message,
                    rejectedMessageInfo: v.rejectedMessageInfo,
                    test: this.checkStatusAndModifiedDate(v.status, v.modified_date) ? (
                        <button
                            type="button"
                            className="btn button-devas"
                            onClick={() => {
                                let checkCombo = this.state.allPubComboPrice.filter(
                                    (value) => value.pubId === v.publisher && value.offerId === v.offer._id
                                );
                                if (checkCombo.length !== 0) {
                                    this.setState({
                                        isComboConversion: true,
                                    });
                                }
                                if (v.offer.isCombo && v.comboLog && checkCombo.length > 0) {
                                    checkCombo[0].price.forEach((value, i) => {
                                        if (value.alias === v.comboLog.alias)
                                            this.setState({
                                                chosenComboPriceID: checkCombo[0]._id,
                                                chosenComboAlias: value.alias,
                                            });
                                    });
                                }
                                this.setState({
                                    currentConversion: v,
                                    newStatusList: this.newStatusMapping(v.status),
                                    showModal: true,
                                    address: v.address,
                                });
                            }}
                        >
                            Edit Status
                        </button>
                    ) : (
                        ""
                    ),
                    comboAlias: (v.comboLog) ? v.comboLog.alias : "",
                }));
                var offers = response.data.map((offer) => {
                    return offer.offer.alias;
                });
                offers = [...new Set(offers)].map((value) => {
                    return { value: value, label: value };
                });
                offers = offers.reverse();
                this.setState(
                    {
                        offerIdOptions: offers,
                        conversions: data,
                        conversionsList: data,
                        isLoadingTable: false,
                    },
                    () => this.filterConversion()
                );
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleChangeOfferId = (offerId) => {
        this.setState({ offerId, isLoadingTable: true }, () => this.filterConversion());
    };
    calculateSummary = (data) => {
        let approved = data.filter((v) => v.status === "approved").length;
        let new_conv = data.filter((v) => v.status === "new").length;
        let callbacks = data.filter((v) => v.status === "callbacks").length;
        let customer_reject = data.filter((v) => v.status === "customer_reject").length;
        let trash = data.filter((v) => v.status === "trash").length;
        let duplicated = data.filter((v) => v.status === "duplicated").length;
        let conversionsTotal = data.length - trash - duplicated === 0 ? 1 : data.length - trash - duplicated;
        let approved_rate = ((approved / conversionsTotal) * 100).toFixed(2);
        let allConversions = data.length;
        this.setState({
            approved,
            new_conv,
            callbacks,
            customer_reject,
            trash,
            duplicated,
            approved_rate,
            allConversions,
        });
    };
    resetState = () => {
        this.setState({
            conversionsList: [],
            conversions: [],
            offerIdOptions: [],
            offerId: [],
            status: "",
            currentConversion: {},
            newStatusList: [],
            newStatus: "",
            statusSaleMessage: "",
            quantity: "1",
            showModal: false,
            isLoadingTable: true,
            approved: "0",
            approved_rate: "0",
            new_conv: "0",
            callbacks: "0",
            trash: "0",
            customer_reject: "0",
            duplicated: "0",
            allConversions: "0",
            search: "",
            address: "",
            rejectedMessageInfo: "",
            chosenComboAlias: "",
            chosenComboPriceID: "",
            isComboConversion: false,
        });
    };
    resetStatePartial = () => {
        this.setState({
            conversionsList: [],
            conversions: [],
            currentConversion: {},
            newStatusList: [],
            newStatus: "",
            statusSaleMessage: "",
            quantity: "1",
            showModal: false,
            isLoadingTable: true,
            approved: "0",
            approved_rate: "0",
            new_conv: "0",
            callbacks: "0",
            trash: "0",
            customer_reject: "0",
            duplicated: "0",
            allConversions: "0",
            address: "",
            rejectedMessageInfo: "",
            chosenComboAlias: "",
            chosenComboPriceID: "",
            isComboConversion: false,
        });
    };
    handleDropDown = (e) => {
        this.setState({ status: e.value, isLoadingTable: true }, () => this.filterConversion());
    };
    handleSearch = (e) => {
        this.setState({ search: e.target.value, isLoadingTable: true }, () => this.filterConversion());
    };
    handleDropDownNewStatus = (e) => {
        this.setState({ newStatus: e.value });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    onChange = (date) =>
        this.setState({ date, isLoadingTable: true }, () => {
            if (this.state.date && this.state.date[0] && this.state.date[1]) {
                this.resetState();
                this.getConversionList();
            } else if (this.state.date == null) {
                this.resetState();
            }
        });
    filterConversion = () => {
        this.setState((state, props) => {
            let conversions = state.conversionsList.filter((conversion) => {
                return (
                    conversion.status.includes(state.status) &&
                    (state.offerId === null || state.offerId.length === 0 || this.checkOfferInList(conversion.offer)) &&
                    (conversion.phone.includes(state.search) ||
                        conversion.name.toLowerCase().includes(state.search.toLowerCase()) ||
                        conversion.order_id.toString().includes(state.search))
                );
            });
            this.calculateSummary(conversions);
            return { conversions, isLoadingTable: false };
        });
    };
    checkOfferInList = (offerId) => {
        return this.state.offerId.some((v) => {
            return offerId == v.value;
        });
    };
    newStatusMapping = (oldStatus) => {
        if (oldStatus === "new") {
            return this.statusListToDropDown(["", "approved", "callbacks", "customer_reject", "trash", "duplicated"]);
        } else if (oldStatus === "callbacks") {
            return this.statusListToDropDown(["", "approved", "callbacks", "customer_reject", "trash", "duplicated"]);
        } else if (oldStatus === "customer_reject") {
            return this.statusListToDropDown(["", "approved", "callbacks", "trash", "duplicated"]);
        } else if (oldStatus === "trash") {
            return this.statusListToDropDown(["", "approved", "callbacks", "customer_reject", "duplicated"]);
        } else if (oldStatus === "duplicated") {
            return this.statusListToDropDown(["", "approved", "callbacks", "customer_reject", "trash"]);
        } else if (oldStatus === "approved") {
            return this.statusListToDropDown(["", "callbacks", "customer_reject", "trash", "duplicated"]);
        }
    };
    statusListToDropDown = (statusList) => {
        return statusList.map((v) => {
            if (v === "") {
                return { value: v, label: "Choose Status" };
            }
            return { value: v, label: v };
        });
    };
    handleNewStatusSubmit = async () => {
        if (this.state.statusSaleMessage === "" || this.state.quantity === "" || this.state.newStatus === "") {
            alert("Please fill in Sale Message, Quantity and a New Status!");
        } else if (parseInt(this.state.quantity).toString() !== this.state.quantity) {
            alert("Quantity must be a number!");
        } else if (
            this.state.currentConversion.offer.isCombo &&
            !this.state.currentConversion.comboLog &&
            this.state.chosenComboPriceID === ""
        ) {
            alert("Please choose a combo!");
        } else if (this.state.newStatus === "customer_reject" && this.state.rejectedMessageInfo === "") {
            alert("Please choose a reject reason!");
        } else if (this.state.isComboConversion && (this.state.chosenComboAlias === "" || this.state.chosenComboAlias === "")) {
            alert("Please choose a combo! This might be an error!");
        } else if (
            this.state.currentConversion.hasOwnProperty("offer") &&
            this.state.currentConversion.offer.hasOwnProperty("isNewProcess") &&
            this.state.currentConversion.offer.isNewProcess &&
            this.state.newStatus === "approved" &&
            (this.state.productPrice == 0 ||
                !this.state.productPrice ||
                this.state.productQuantity == 0 ||
                !this.state.productQuantity ||
                !this.state.shippingCost)
        ) {
            alert("Please fill in Product Price, Product Quantity, Shipping Cost!");
        } else {
            let body = {
                conversion: this.state.currentConversion._id,
                new_status: this.state.newStatus,
                quantity: this.state.quantity,
                sale_message: this.state.statusSaleMessage,
                address: this.state.address,
                rejected_message_info: this.state.rejectedMessageInfo,
            };
            if (this.state.isComboConversion) {
                body.comboPriceID = this.state.chosenComboPriceID;
                body.comboAlias = this.state.chosenComboAlias;
            }
            if (
                this.state.currentConversion.hasOwnProperty("offer") &&
                this.state.currentConversion.offer.hasOwnProperty("isNewProcess") &&
                this.state.currentConversion.offer.isNewProcess &&
                this.state.newStatus === "approved"
            ) {
                let rate = 1;
                if(this.state.currency.value === "THB") {
                    rate = await THBtoVND();
                    rate = rate.toFixed(0);
                }
                let total = (this.state.productPrice * this.state.productQuantity + parseInt(this.state.shippingCost)) * rate;
                body.income = total;
                body.incomeDetail = {
                    productPrice: this.state.productPrice,
                    productQuantity: this.state.productQuantity,
                    shippingCost: this.state.shippingCost,
                    currency: this.state.currency.value,
                }
                console.log(body.income);
                console.log(body.incomeDetail);
            }
            console.log(body);
            axios
                .put(`${process.env.REACT_APP_SALE_URL}/conversions`, body, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_sale"),
                    },
                })
                .then((response) => {
                    this.resetStatePartial();
                    this.viewPendingConversions(moment(new Date("2020-05-15T00:00:00")).startOf("date").unix() * 1000);
                    alert("Operation successful!");
                })
                .catch((error) => {
                    alert("Something Wrong Happended!" + error.response.data.message);
                    //this.props.history.push("/error?message=" + error.message);
                });
        }
    };
    handleModalClose = () => {
        this.setState({
            showModal: false,
            address: "",
            newStatus: "",
            quantity: "1",
            statusSaleMessage: "",
            chosenComboAlias: "",
            chosenComboPriceID: "",
            rejectedMessageInfo: "",
            isComboConversion: false,
            shippingCost: 0,
            productPrice: 0,
            productQuantity: 0,
            currency: { value: "THB", label: "THB" },
        });
    };

    viewPendingConversions(date1) {
        this.getImportantConversionsCount();
        this.setState({ isLoadingTable: true });
        this.resetStatePartial();
        this.setState({ date: [date1, new Date()] }, () => {
            this.signal = axios.CancelToken.source();
            let from_date = moment(this.state.date[0]).startOf("date").unix() * 1000;
            let to_date = moment(this.state.date[1]).endOf("date").unix() * 1000 + 999;
            axios
                .get(`${process.env.REACT_APP_SALE_URL}/conversions?from_date=${from_date}&to_date=${to_date}`, {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_sale"),
                    },
                })
                .then((response) => {
                    let data = response.data.reverse().map((v) => ({
                        _id: v._id,
                        order_id: v.order_id,
                        created_date: new Date(v.created_date).toLocaleString(),
                        modified_date: new Date(v.modified_date).toLocaleString(),
                        offer: v.offer.alias,
                        status: v.status,
                        modify_count: v.modify_count,
                        name: v.name,
                        phone: v.phone,
                        quantity: v.quantity,
                        address: v.address,
                        sale_message: v.sale_message,
                        message: v.message,
                        rejectedMessageInfo: v.rejectedMessageInfo,
                        test: this.checkStatusAndModifiedDate(v.status, v.modified_date) ? (
                            <button
                                type="button"
                                className="btn button-devas"
                                onClick={() => {
                                    let checkCombo = this.state.allPubComboPrice.filter(
                                        (value) => value.pubId === v.publisher && value.offerId === v.offer._id
                                    );
                                    if (checkCombo.length !== 0) {
                                        this.setState({
                                            isComboConversion: true,
                                        });
                                    }
                                    if (v.offer.isCombo && v.comboLog && checkCombo.length > 0) {
                                        checkCombo[0].price.forEach((value, i) => {
                                            if (value.alias === v.comboLog.alias)
                                                this.setState({
                                                    chosenComboPriceID: checkCombo[0]._id,
                                                    chosenComboAlias: value.alias,
                                                });
                                        });
                                    }
                                    this.setState({
                                        currentConversion: v,
                                        newStatusList: this.newStatusMapping(v.status),
                                        showModal: true,
                                        address: v.address,
                                    });
                                }}
                            >
                                Edit Status
                            </button>
                        ) : (
                            ""
                        ),
                        comboAlias: (v.comboLog) ? v.comboLog.alias : "",
                    }));
                    data = data.reverse();
                    data.sort(function (a, b) {
                        const aStatus = a.status.toLowerCase();
                        const bStatus = b.status.toLowerCase();
                        if (aStatus !== "new" && bStatus === "new") return 1;
                        else return -1;
                    });
                    var dataConversions = data.filter((conv) => conv.status === "new" || conv.status === "callbacks");
                    var offers = response.data.map((offer) => {
                        return offer.offer.alias;
                    });
                    offers = [...new Set(offers)].map((value) => {
                        return { value: value, label: value };
                    });
                    offers = offers.reverse();
                    this.calculateSummary(dataConversions);
                    this.setState({
                        offerIdOptions: offers,
                        conversions: dataConversions,
                        conversionsList: data,
                        isLoadingTable: false,
                    });
                })
                .catch((error) => {
                    //this.props.history.push("/error?message=" + error.message);
                });
        });
    }

    checkStatusAndModifiedDate = (status, modified_date) => {
        if ((status === "approved" && Date.now() - new Date(modified_date).getTime() < 86400000) || status !== "approved")
            return true;
        return false;
    };
    handleComboSelected = (combo, comboPriceID) => {
        this.setState({ chosenComboAlias: combo.alias, chosenComboPriceID: comboPriceID });
    };
    handleDropDownRejectedMessageInfo = (e) => {
        this.setState({ rejectedMessageInfo: e.value });
    };
    handleChangeCurrency = (e) => {
        this.setState({ currency: e });
    };
    recentCallbacks = () => {
        this.setState({ date: [new Date().setDate(new Date().getDate() - 5), Date.now()], status: "callbacks" }, () => {
            this.getConversionList();
        });
    };
    recentNew = () => {
        this.setState({ date: [new Date().setDate(new Date().getDate() - 5), Date.now()], status: "new" }, () => {
            this.getConversionList();
        });
    };
    // modifiedToday = () => {
    //     this.setState({ modified_date: { value: "true", label: "Modified Date" }, status: "" }, () => this.getConversionList(true));
    // };
    createdToday = () => {
        this.setState({ status: "" }, () => this.getConversionList(true));
    }

    render() {
        var {
            offerIdOptions,
            status,
            currentConversion,
            newStatusList,
            statusSaleMessage,
            newStatus,
            quantity,
            showModal,
            conversionPoolCount,
            approved_rate,
            approved,
            new_conv,
            callbacks,
            trash,
            customer_reject,
            duplicated,
            allConversions,
            search,
            address,
            productPrice,
            productQuantity,
            shippingCost,
            currency,
        } = this.state;
        let rejectedMessageSelect = "";
        if (newStatus === "customer_reject") {
            let rejectedMessageOptions = [
                { label: "Cancel", value: "cancel" },
                { label: "Can't Connect", value: "cannot_connect" },
                { label: "Can't delivery", value: "cannot_delivery" },
                { label: "Just asking", value: "just_asking" },
                { label: "Price", value: "price" },
                { label: "Wrong Order", value: "wrong_order" },
                { label: "Refusal on delivery", value: "refusal_on_delivery" },
                { label: "Others", value: "others" },
            ];
            rejectedMessageSelect = (
                <div className="mb-3">
                    Reason why this conversion is rejected:
                    <Dropdown
                        options={rejectedMessageOptions}
                        onChange={this.handleDropDownRejectedMessageInfo}
                        value={this.state.rejectedMessageInfo}
                        placeholder="Choose Reason"
                        className="d-inline-block"
                    />
                </div>
            );
        }
        let comboChoices = "";
        if (currentConversion.hasOwnProperty("offer") && currentConversion.offer.isCombo) {
            let thisComboPrice = this.state.allPubComboPrice.filter((item) => {
                return (
                    item.pubId === this.state.currentConversion.publisher &&
                    item.offerId === this.state.currentConversion.offer._id
                );
            });
            comboChoices = (
                <div className="mb-3">
                    Choose Combo:
                    <div className="row mt-2">
                        {thisComboPrice[0].price.map((item, index) => {
                            return (
                                <div className="col-4" onClick={() => this.handleComboSelected(item, thisComboPrice[0]._id)}>
                                    <div
                                        className={
                                            this.state.chosenComboAlias === item.alias
                                                ? "comboChoiceSelected bg-menu px-2"
                                                : "comboChoice bg-menu px-2"
                                        }
                                    >
                                        <p className="my-1">Name: {item.name}</p>
                                        <p className="my-1">Description: {item.description}</p>
                                        <p className="my-1">Price: {moneyFormat(parseFloat(item.price))}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
        let newProcess = "";
        if (
            currentConversion.hasOwnProperty("offer") &&
            currentConversion.offer.hasOwnProperty("isNewProcess") &&
            currentConversion.offer.isNewProcess &&
            newStatus === "approved"
        ) {
            newProcess = (
                <div>
                    <div className="mb-3">
                        Currency:{"  "}
                        <Select
                            value={currency}
                            options={[
                                { value: "THB", label: "THB" },
                                { value: "VND", label: "VND" },
                            ]}
                            placeholder="Offer Id"
                            className="devas-select"
                            classNamePrefix="devas-select"
                            onChange={this.handleChangeCurrency}
                            theme={selectTheme}
                        />
                    </div>
                    <div className="mb-3">
                        Price:{"  "}
                        <input
                            type="number"
                            className="form-control search-input mt-2 w-100 d-inline-block"
                            name="productPrice"
                            value={productPrice}
                            onChange={this.handleChange}
                            placeholder="Product Price"
                        />
                    </div>
                    <div className="mb-3">
                        Product Quantity:{"  "}
                        <input
                            type="number"
                            className="form-control search-input mt-2 w-auto d-inline-block"
                            name="productQuantity"
                            value={productQuantity}
                            onChange={this.handleChange}
                            placeholder="Product Quantity"
                        />
                    </div>
                    <div className="mb-3">
                        Customer Shipping Cost{": "}
                        <input
                            type="number"
                            className="form-control search-input mt-2 w-auto d-inline-block"
                            name="shippingCost"
                            value={shippingCost}
                            onChange={this.handleChange}
                            placeholder="Shipping Cost"
                        />
                    </div>
                </div>
            );
        }
        return (
            <Main history={this.props.history} linkTo={["/sale/dashboard"]} linkName={["Dashboard"]} selectedSection={1}>
                <div className="col-9"></div>
                <div className="col-6 col-lg-3 pb-3">
                    <input
                        type="text"
                        className="form-control search-input"
                        name="search"
                        value={search}
                        onChange={this.handleSearch}
                        placeholder="Search..."
                    />
                </div>
                <div className="col-6 col-lg-3 pb-3">
                    <Select
                        defaultValue={""}
                        isMulti
                        name="offerId"
                        options={offerIdOptions}
                        placeholder="Offer Id"
                        className="devas-select"
                        classNamePrefix="devas-select"
                        onChange={this.handleChangeOfferId}
                        theme={selectTheme}
                    />
                </div>
                <div className="col-6 col-lg-3 pb-3">
                    <Dropdown
                        options={[
                            { value: "", label: "All" },
                            { value: "approved", label: "Approved" },
                            { value: "new", label: "New" },
                            { value: "callbacks", label: "Callbacks" },
                            { value: "customer_reject", label: "Customer Reject" },
                            { value: "duplicated", label: "Duplicated" },
                            { value: "trash", label: "Trash" },
                        ]}
                        onChange={this.handleDropDown}
                        value={status}
                        placeholder="Status"
                    />
                </div>

                <div className="col-6 col-lg-3 pb-3">
                    <div className="d-flex align-items-center bg-daterangepicker">
                        <DateRangePicker onChange={this.onChange} value={this.state.date} />
                    </div>
                </div>
                <Modal
                    show={showModal}
                    onHide={this.handleModalClose}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Edit Status</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-3">{"Order Id: " + currentConversion.order_id}</p>
                        <p className="mb-3">{"Name: " + currentConversion.name}</p>
                        <p className="mb-3">
                            Phone: <a href={`tel:${currentConversion.phone}`}>{currentConversion.phone}</a>
                        </p>
                        <p className="mb-3">{"Current Status: " + currentConversion.status}</p>
                        {currentConversion.sale_message && (
                            <p className="mb-3">{"Current Sale Message: " + currentConversion.sale_message}</p>
                        )}
                        <div className="mb-3">
                            Address:{"  "}
                            <input
                                type="text"
                                className="form-control search-input mt-2 w-100 d-inline-block"
                                name="address"
                                value={address}
                                onChange={this.handleChange}
                                placeholder="Address"
                            />
                        </div>
                        <div className="mb-3">
                            Quantity:{"  "}
                            <input
                                type="text"
                                className="form-control search-input mt-2 w-auto d-inline-block"
                                name="quantity"
                                value={quantity}
                                onChange={this.handleChange}
                                placeholder="Quantity"
                            />
                        </div>
                        <div className="mb-3">
                            New Status{": "}
                            <Dropdown
                                options={newStatusList}
                                onChange={this.handleDropDownNewStatus}
                                value={newStatus}
                                placeholder="Choose Status"
                                className="d-inline-block"
                            />
                        </div>
                        {rejectedMessageSelect}
                        {comboChoices}
                        {newProcess}
                        <div>
                            New Sale Message:{" "}
                            <input
                                type="text"
                                className="form-control search-input mt-2"
                                name="statusSaleMessage"
                                value={statusSaleMessage}
                                onChange={this.handleChange}
                                placeholder="Fill in here"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModalClose}>
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn button-devas"
                            onClick={this.handleNewStatusSubmit}
                            // onClick={this.handleModalClose}
                        >
                            Save changes
                        </button>
                    </Modal.Footer>
                </Modal>
                <SummaryStatistics
                    approved={approved}
                    approved_rate={approved_rate}
                    new_conv={new_conv}
                    callbacks={callbacks}
                    trash={trash}
                    customer_reject={customer_reject}
                    duplicated={duplicated}
                    allConversions={allConversions}
                    changeStatus={(status) => this.setState({ status }, () => this.filterConversion())}
                />
                <ConversionList
                    conversions={this.state.conversions}
                    isLoading={this.state.isLoadingTable}
                    conversionPoolCount={conversionPoolCount}
                    recentPending={() =>
                        this.viewPendingConversions(
                            moment(Date.now() - 86400000)
                                .startOf("date")
                                .unix() * 1000
                        )
                    }
                    todayConversions={this.createdToday}
                    allPending={() =>
                        this.viewPendingConversions(moment(new Date("2020-05-15T00:00:00")).startOf("date").unix() * 1000)
                    }
                    importantConversionsCount={{
                        recentCallbacksCount: this.state.recentCallbacksCount,
                        recentNewCount: this.state.recentNewCount,
                        createdTodayCount: this.state.createdTodayCount,
                        modifiedTodayCount: this.state.modifiedTodayCount,
                        allPendingCount: this.state.allPendingCount,
                    }}
                    recentCallBacks={this.recentCallbacks}
                    recentNew={this.recentNew}
                    // modifiedToday={this.modifiedToday}
                />
            </Main>
        );
    }
}
