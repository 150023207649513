import React, {Component} from "react";
import DataTable from "react-data-table-component";
import {ExportExcel} from "../../helper/ExportExcel";
import {customStyles} from "../../style/table";
import RefreshIcon from "@material-ui/icons/Refresh";

const columns = [
    {
        name: "",
        selector: "test",
        sortable: true,
        wrap: true,
    },
    {
        name: "Order Id",
        selector: "order_id",
        maxWidth: "70px",
        sortable: true,
        center: true,
    },
    {
        name: "Name",
        selector: "name",
        sortable: true,
        wrap: true,
    },
    {
        name: "Combo Alias",
        selector: "comboAlias",
        sortable: true,
        wrap: true,
    },
    {
        name: "Phone",
        selector: "phone",
        sortable: true,
        wrap: true,
    },
    {
        name: "Address",
        selector: "address",
        sortable: true,
        wrap: true,
    },
    {
        name: "Status",
        selector: "status",
        sortable: true,
        wrap: true,
        conditionalCellStyles: [
            {
                when: row => row.status === "approved",
                style: {
                    color: "var(--green)"
                }
            },
            {
                when: row => row.status === "new" || row.status === "callbacks",
                style: {
                    color: "var(--orange)"
                }
            },
            {
                when: row => row.status === "customer_reject",
                style: {
                    color: "var(--red)"
                }
            },
            {
                when: row => row.status === "duplicated" || row.status === "trash",
                style: {
                    color: "var(--blue)"
                }
            }

        ]
    },
    {
        name: "Quantity",
        selector: "quantity",
        sortable: true,
        wrap: true,
    },
    {
        name: "Price",
        selector: "price",
        sortable: true,
        wrap: true,
    },
    {
        name: "Offer Name",
        selector: "offer",
        sortable: true,
        wrap: true,
    },
    {
        name: "Modify Count",
        selector: "modify_count",
        sortable: true,
        wrap: true,
    },
    {
        name: "Sale",
        selector: "sale_username",
        sortable: true,
        wrap: true,
    },
    {
        name: "Sale Log",
        selector: "sale_message",
        sortable: true,
        wrap: true,
    },
    {
        name: "Rejected Reason",
        selector: "rejectedMessageInfo",
        sortable: true,
        wrap: true,
    },
    {
        name: "Message",
        selector: "message",
        sortable: true,
        wrap: true
    },
    {
        name: "Created Date",
        selector: "created_date",
        sortable: true,
        wrap: true,
    },
    {
        name: "Modified Date",
        selector: "modified_date",
        sortable: true,
        wrap: true,
    },
];
export default class ConversionList extends Component {
    constructor(props) {

        super(props);
    }

    render() {
        return (
            <>
                <div className="col-12 Statistics">
                    <div className="py-3 bg-menu">
                        <div className="px-4 d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <h2 className="menu-h2-title mb-0">Conversion List</h2>
                                <span className="cursor-pointer ml-1" onClick={this.props.reloadConversions}>
                                    <RefreshIcon/>
                                </span>
                                <button
                                    className="btn button-devas ml-2 d-none d-md-inline-block"
                                    onClick={this.props.allPending}
                                >
                                    All Pending
                                    ({this.props.importantConversionsCount.allPendingCount})
                                </button>
                                <button
                                    className="btn button-devas ml-2 d-none d-md-inline-block"
                                    onClick={this.props.recentCallBacks}
                                >
                                    Recent Callbacks ({this.props.importantConversionsCount.recentCallbacksCount})
                                </button>
                                <button
                                    className="btn button-devas ml-2 d-none d-md-inline-block"
                                    onClick={this.props.recentNew}
                                >
                                    Recent New ({this.props.importantConversionsCount.recentNewCount})
                                </button>
                                <button
                                    className="btn button-devas ml-2 d-none d-md-inline-block"
                                    onClick={this.props.todayConversions}
                                >
                                    Created Today ({this.props.importantConversionsCount.createdTodayCount})
                                </button>
                                <button
                                    className="btn button-devas ml-2 d-none d-md-inline-block"
                                    onClick={this.props.modifiedToday}
                                >
                                    Modified Today ({this.props.importantConversionsCount.modifiedTodayCount})
                                </button>
                            </div>
                            <div className="d-flex align-items-center">
                                {/* <p className="mb-0 small-text d-inline-block mr-2">Unassigned new conversions: {this.props.conversionPoolCount}</p> */}
                                <button
                                    className="btn button-devas d-inline-block mr-2"
                                    onClick={() => this.props.history.push("/adv/assignconversions")}
                                >
                                    Assign New Conversions ({this.props.conversionPoolCount})
                                </button>
                                <button className="btn button-devas d-inline-block mr-2"
                                        onClick={() => this.props.openModal()}>
                                    Move Conversions
                                </button>
                                <ExportExcel
                                    csvData={this.props.conversions}
                                    fileName={"AdvConversions-" + Date.now().toLocaleString()}
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={this.props.conversions}
                        pagination
                        theme="myTheme"
                        className="table"
                        customStyles={customStyles}
                        noHeader={true}
                        progressPending={this.props.isLoading}
                        highlightOnHover={true}
                        defaultSortField="order"
                        defaultSortAsc={false}
                        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                    />
                </div>
            </>
        );
    }
}
