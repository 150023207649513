import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class NotFound extends Component {
    componentDidMount(){
        document.title = "404 Not Found"
    }
    render() {
        return (
            <div className="d-flex w-100vw h-100vh justify-content-center align-items-center font-raleway flex-column">
                <h1 className="display-1">404 Not Found</h1>
                <h2 className="mt-3">
                    Go back to <Link to="/">home</Link>
                </h2>
            </div>
        );
    }
}
