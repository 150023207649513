export const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    backgroundColor: "var(--bg-menu)",
    colors: {
        ...theme.colors,
        primary25: "var(--secondary)",
        primary: "var(--bg-menu)",
        primary50: "var(--secondary-light)",
        neutral0: "var(--bg-menu)",
        neutral5: "var(--blue)",
        neutral10: "var(--bg-auth)",
        neutral20: "var(--text)",
        neutral30: "var(--text)",
        neutral40: "var(--text)",
        neutral70: "var(--text)",
        neutral80: "var(--text)",
        neutral90: "var(--text)",
    },
})